export const FirebaseConfig = {
	"projectId": "recibeloya-86fef",
	"appId": "1:210206372808:web:424d798baf40bbb43fc6fd",
	"databaseURL": "https://recibeloya-86fef-default-rtdb.firebaseio.com",
	"storageBucket": "recibeloya-86fef.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCEgsmf5O0qM0ZNH0mHlnDoHxStrAMDiVw",
	"authDomain": "recibeloya-86fef.firebaseapp.com",
	"messagingSenderId": "210206372808",
	"measurementId": "G-EXPFWESK36"
};